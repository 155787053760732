import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var index = ref(3);

    var handleGuige = function handleGuige() {
      index.value = 3;
      router.push("/introduction");
    };

    var handleRuanjian = function handleRuanjian() {
      index.value = 2;
      router.push("/software");
    };

    var handleBuy = function handleBuy() {
      index.value = 3;
      router.push("/buy");
    };

    var gotobuy1 = function gotobuy1() {
      window.open("https://item.jd.com/10066666807464.html");
    };

    var gotobuy2 = function gotobuy2() {
      window.open("https://shop257782356.taobao.com/category-1431690426.htm?spm=a1z10.1-c-s.w5002-21359729508.3.58d53ae8y0zaI1&search=y&catName=%BB%E6%B1%BE%CD%A8%B5%E7%D7%D3%D4%C4%B6%C1%C6%F7");
    };

    onMounted(function () {
      handlebackTop();
    });

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView(); // document.getElementById('content1').scrollIntoView()
    };

    return {
      index: index,
      handleGuige: handleGuige,
      handleRuanjian: handleRuanjian,
      handleBuy: handleBuy,
      gotobuy1: gotobuy1,
      gotobuy2: gotobuy2,
      handlebackTop: handlebackTop
    };
  }
});